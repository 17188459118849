<template>
  <div>
    <div class="mt-12 ">
      <AlbumSearch />
      <h1 class="heading-text m-4 text-2xl xs:text-3xl sm:text-5xl text-[#575555] font-extrabold">Music never looked so good</h1>
      <div
        v-if="showFeaturedGrid && albums"
        class="mb-10">
        <AlbumGrid
          :albums="albums"
          :allowDrag="false"
          :showBackCovers="true"
          :showArtistAndTitle="true"
          :classes="'overflow-y-scroll grid gap-x-3 gap-y-8 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 mb-4 h-auto px-4 pb-4'" />
        <div
          v-if="allowLoadMore"
          class="w-full flex justify-center mb-24">
          <button type="submit"
                  class="button w-64 h-12 group"
                  @click.prevent="handleLoadMore"
                  :disabled="loading">
            <span v-if="!loading">Load more</span>
            <span v-else>
              <Loader class="group-hover:text-white dark:group-hover:text-black"></Loader>
            </span>
          </button>
        </div>
      </div>

      <div v-if="albumBeingCreated" class="fixed left-0 top-0 w-full h-full wrapper flex items-center justify-center">
        <div class="w-1/4 md:w-1/12">
          <Loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import { mapState, mapGetters } from 'vuex'

import AlbumSearch from '@/components/AlbumSearch'
import AlbumGrid from '@/components/AlbumGrid'
import { Polygon } from '@/polygon'
import CONSTANTS from '@/constants'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter
})

export default {
  name: 'Home',
  data(){
    return {
      CONSTANTS,
      Polygon,
      loading: true,
      showSelectedAlbum: false,
      selectedAlbum: {},
      sortAToZ: true,
      showFeaturedGrid: true,
      filledPage: false,
      albumBeingCreated: false,
    }
  },
  components: {
    AlbumSearch,
    AlbumGrid,
  },
  computed: {
    ...mapState('album', ['albums', 'allowLoadMore']),
    ...mapGetters('auth', ['loggedIn']),
  },
  methods: {
    creatingAlbum(){
      this.albumBeingCreated = true
    },
    sortRecent(){
      this.albums.sort((a, b) => a.updated_at < b.updated_at);
    },
    sortAlphabetic(){
      if(this.sortAToZ){
        this.sortAToZ = false
        this.albums.sort((a, b) => a.artist > b.artist);
      }else{
        this.sortAToZ = true
        this.albums.sort((a, b) => a.artist < b.artist);
      }

    },
    sortRandom(){
      this.albums = this.albums.sort(() => Math.random() - 0.5)
    },
    handleLoadMore() {
      this.loading = true

      /*let size = Math.floor(window.innerHeight)
      if(window.innerHeight > window.innerWidth){
        size = Math.floor(window.innerWidth)
      }*/

      let services = 'all'
      if(this.$route.name === 'tidal' || this.$store.getters['tidal/connected']){
        services = 'tidal'
      }
      api({
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/albums/featured?size=350&services=${services}&page=${this.$store.state.album.page}`,
        method: 'get'
      }).then(async (response) => {
        if(response.data.data.length > 0){
          this.$store.dispatch('album/addToAlbums', response.data.data)
          this.$store.commit('album/INCREMENT_PAGE')
        }

        if(!response.data.links.next){
          this.$store.commit('album/SET_ALLOW_LOAD_MORE', false)
        }else{
          this.$store.commit('album/SET_ALLOW_LOAD_MORE', true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })

      /*axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/albums/featured?size=250&services=${services}&page=${this.$store.state.album.page}`)
      .then((response) => {
        if(response.data.data.length > 0){
          this.$store.dispatch('album/addToAlbums', response.data.data)
          this.$store.commit('album/INCREMENT_PAGE')
        }

        if(!response.data.links.next){
          this.$store.commit('album/SET_ALLOW_LOAD_MORE', false)
        }else{
          this.$store.commit('album/SET_ALLOW_LOAD_MORE', true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })*/
    },
  },
  beforeCreate: function() {
    //document.body.className = 'home'
  },
  mounted(){
    this.$store.dispatch('album/clearAlbum')
    this.$root.$on('album-selected', this.albumSelected)
    this.$root.$on('creating-album', this.creatingAlbum)

    this.$store.commit('collection/SET_PREV_USERNAME', null)

    //let vh = window.innerHeight * 0.01
    //document.documentElement.style.setProperty('--vh', `${vh}px`)

    if(this.showFeaturedGrid && this.albums.length === 0){
      this.handleLoadMore()
    }else{
      this.loading = false
    }

    /*window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        this.handleLoadMore()
      }
    };*/
  },
  beforeDestroy(){
    this.$root.$off('album-selected', this.albumSelected)
    this.$root.$off('creating-album', this.creatingAlbum)
  },
  created(){
    document.addEventListener("keyup", this.keyup);
  },
  destroyed: function() {
    document.removeEventListener("keyup", this.keyup);
  },
}
</script>

<style>
 /* Hide scrollbar for Chrome, Safari and Opera */
#app::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#app {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/*@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("~@/assets/fonts/Inter/Inter-Medium.woff2?v=3.15") format("woff2"),
       url("~@/assets/fonts/Inter/Inter-Medium.woff?v=3.15") format("woff");
}

body {
  background: black;
  color: white;
}*/
</style>