<template>
  <div class="mx-4 mt-4 mb-auto h-auto flex flex-col justify-center items-start">
      <div class="w-full max-w-screen-xs">
        <form class="mt-1 flex w-full" @submit.prevent="onClickSearch">
          <div class="relative w-full">
            <input
              class="w-full bg-gray-300 bg-opacity-20 placeholder-gray-400 border-2 border-transparent dark:border-transparent rounded-xl h-12 px-5 pr-12 focus:border-black dark:focus:border-white active:border-black dark:active:border-white focus:outline-none active:outline-none"
              type="text"
              name="search"
              v-model="term"
              placeholder="Search"
              @keydown.esc="clearTerm"
              @keydown.backspace="backspaceClicked" />
            <button
              type="submit"
              class="absolute right-0 top-0"
              :class="{ 'cursor-not-allowed': !term || isSearching}"
              :disabled="!term || isSearching">
              <svg
                v-if="!isSearching"
                class="mt-3 mr-3 h-6 w-6 text-gray-400"
                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 28 28" style="enable-background:new 0 0 28 28;" xml:space="preserve">
                <g>
                  <g>
                    <path fill="currentColor" d="M12.4,6.5c3.25,0,5.9,2.65,5.9,5.9s-2.65,5.9-5.9,5.9s-5.9-2.65-5.9-5.9S9.15,6.5,12.4,6.5 M12.4,4.5
                      c-4.36,0-7.9,3.54-7.9,7.9s3.54,7.9,7.9,7.9s7.9-3.54,7.9-7.9S16.77,4.5,12.4,4.5L12.4,4.5z"/>
                  </g>
                  <g>
                    <line fill="currentColor" style="fill:none;stroke:currentColor;stroke-width:2;stroke-miterlimit:10;" x1="23.5" y1="23.5" x2="16.79" y2="16.79"/>
                  </g>
                </g>
              </svg>
              <div
                v-else
                class="mt-2.5 mr-3 w-8">
                <Loader></Loader>
              </div>
            </button>
          </div>
        </form>
        <AlbumsList v-if="searchResults" :albums="searchResults"></AlbumsList>
        <h2 v-if="searchResults.length == 0 && hasPerformedSearch">
          No matching results
        </h2>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import AlbumsList from '@/components/AlbumsList'
export default {
  name: 'AlbumSearch',
  components: {
    AlbumsList
  },
  data(){
    return {
      term: '',
      prevTerm: null,
      isSearching: false,
      hasPerformedSearch: false,
      serverError: '',
      searchResults: []
    }
  },
  watch: {
    //watch for query string to change and
    //set the search field when it does
    "$route.query.term": function() {
      this.term = this.$route.query.term
      if (this.term) {
        this.submitSearch()
      } else {
        this.isSearching = false
        this.hasPerformedSearch = false
        this.setSearchResults([])
      }
    },
    term(value){
      if(value === ''){
        this.setSearchResults([])
        this.hasPerformedSearch = false
      }
    }
  },
  methods: {
    async clearTerm(){
      this.term = ''
      this.prevTerm = null
      this.isSearching = false
      this.hasPerformedSearch = false
      this.setSearchResults([])
      this.$router.replace({'query': null}).catch(()=>{})
    },
    backspaceClicked(){
      this.prevTerm = null
      this.hasPerformedSearch = false
    },
    setSearchResults(results){
      this.searchResults = results
    },
    async onClickSearch() {
      if (!this.term) return;

      if(this.term === this.prevTerm)return;
      this.prevTerm = this.term

      this.hasPerformedSearch = false;
      this.setSearchResults([]);

      if(this.term && this.term.startsWith("@")){
        await this.$router.replace({
          name: 'usercollection',
          params: { username: this.term.slice(1) },
        });
      }else{
        //change the query string
        //a watch method checks for this and
        //performs the search
        if(this.term !== this.$route.query.term){
          await this.$router.replace({
            query: { term: this.term },
          });
        }else{
          this.submitSearch()
        }

      }

    },
    submitSearch() {
      this.isSearching = true;

      axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/search?term=${this.term}&service=spotify`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.token
          }
        })
        .then((response) => {
          this.searchResults = response.data.albums;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hasPerformedSearch = true
          this.isSearching = false
        })


    },

  },
  mounted(){
    //get the query string and
    //set the search field when app mounts
    this.term = this.$route.query.term;
    if (this.term) {
      this.submitSearch();
    } else {
      this.setSearchResults([]);
    }
  }
}
</script>