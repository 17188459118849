<template>
  <div>
    <draggable
      v-bind="dragOptions"
      v-model="orderedAlbums"
      @change="orderChanged = true"
      :class="classes">
      <div
        v-for="album in orderedAlbums"
        :key="album.id"
        @click.stop="albumClicked(album)"
        class="focus:outline-none select-none transition-opacity border-gray-300 dark:border-gray-300 cursor-pointer hover:opacity-80"
        :class="[dragEnabled ? 'm-1 p-2 border-2 border-dashed' : '' ]"
      >
        <div
          class="relative w-full"
          style="padding:4%">
          <img
            v-if="album.back_cover && !dragEnabled"
            :src="album.back_cover"
            loading="lazy"
            class="absolute shadow-grid w-full h-full object-cover"
            style="width:92%; height: 92%; left: 0; bottom: 0" />
          <transition name="fade">
            <img v-if="album.is_nft && album.owned_by_user" class="absolute left-0 top-0 shadow-grid w-full h-full object-cover" src="@/assets/gif/nft_owner.gif" />
            <img v-else-if="album.is_nft && album.owned_by_user === false" class="absolute left-0 top-0 shadow-grid w-full h-full object-cover" src="@/assets/gif/nft.gif" />
          </transition>
          <img
            :src="album.front_cover"
            loading="lazy"
            class="cover relative shadow-grid w-full h-full object-cover" />
        </div>
        <div v-if="showArtistAndTitle" class="ml-4 mt-2 text-white grid-text">
          <div class="font-normal">{{ album.artist }}</div>
          <div class="font-black">{{ album.title }}</div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

export default {
  name: 'AlbumGrid',
  components: {
    draggable
  },
  props: {
    classes: {
      type: String,
      default: 'overflow-y-scroll grid gap-3 grid-cols-3 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 mb-4 h-auto px-4 pb-4'
    },
    showArtistAndTitle: {
      type: Boolean,
      default: false
    },
    albums: {
      type: Array,
    },
    dragEnabled: {
      type: Boolean,
      default: false
    },
    showBackCovers: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
    allowSelect: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      orderedAlbums: this.albums,
      orderChanged: false,
      visibilityChanged: false,
    }
  },
  watch: {
    albums(val){
      this.orderedAlbums = val
    },
    dragEnabled(val){
      if(!val && this.orderChanged){
        this.orderChanged = false
        this.$emit('drag-complete', this.orderedAlbums)
      }
    },
  },
  computed: {
    ...mapState('auth', {
      user: 'user'
    }),
    dragOptions(){
      return {
        animation: 250,
        group: 'albums',
        disabled: !this.dragEnabled,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    albumClicked(album){
      if(!this.allowSelect){
        this.$router.push({ name: 'signup' })
        return;
      }

      if(this.dragEnabled)return;

      this.$router.push({ name: 'release', params: { id: album.id } })
    },
    toggleVisibility(album){
      album.visible = !album.visible
    },
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
}
.cover{
  aspect-ratio: 1;
}
</style>
